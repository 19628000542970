import React, { ReactNode, useState, useRef } from "react";
import { Flex, Box, Image, Button, Text, AlertDialog, AlertDialogOverlay, 
    AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Cookie from "universal-cookie";
import hcLogo from '../../static/images/hc-logo.png';

interface IChild {
    children: ReactNode;
}

const Layout = ({children}: IChild) => {

    const initialRef = useRef<any>();
    const history = useHistory();
    const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);

    function closeCookieTimeout() {
        setCookieTimeoutWindow(false);
        history.push("/");
    }

    function handleCookie() {
        const cookie = new Cookie();
        if (cookie.get("authenticate"))
            history.push("/home")
        else
            setCookieTimeoutWindow(true);
    }
    return (
        <Flex 
            minH = {"100vh"} 
            direction = "column" 
            bg = "#FBFFF8">
            <Flex as = "button" onClick = {handleCookie} justifyContent = "space-around" alignItems = "center" height = "12vh">
                <Image src = {hcLogo} width="300px" height="100px" />
            </Flex>
            <Box 
            mx = {"auto"}
            width = {["95%", "93%", "90%", "80%", "70%"]}>
                {children}
            </Box>

            {/* -------------- Cookies Timeout window -------------- */}
            <AlertDialog
                isOpen = {cookieTimeoutWindow}
                motionPreset = "slideInBottom"
                onClose = {closeCookieTimeout}
                leastDestructiveRef = {initialRef}
                isCentered>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>Cookies timeout. Please login again.</Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref = {initialRef} colorScheme = "teal" onClick = {() => closeCookieTimeout()}>
                                Okay
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Flex>

        

    );
}

export default Layout;