import { baseRequest } from "./baseRequest";

const url = "/admin/portal/organization/";

interface IProps {
    chineseName: string,
    englishName: string,
    orgInput: string
    type: string,
    user: string
}

export const adminPatchOrganization = (props:IProps) => {
    return (
        baseRequest.put(
            url + props.orgInput,
        {
            name_l1: props.chineseName,
            name_l2: props.englishName,
            type: props.type
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return (response.data);
        })

        .catch((err) => {
            if (err.response.status === 400)
                return err.response;
            else
                throw (err);
        })
    );
}