import { baseRequest } from "./baseRequest";

const url = "/portal/register";

type syncDataBy = "p" | "c"

interface IProps {
    orgInput: string,
    id: number,
    userName: string,
    password: string,
    syncDataBy: syncDataBy,
    user: string
}

export const portalRegister = (props:IProps) => {
    return (
        baseRequest.post(url, {
            organizer: props.orgInput,
            organization_id: props.id,
            user_name: props.userName,
            password: props.password,
            sync_data_by: props.syncDataBy
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return response.data;
        })

        .catch ((err) => {
            throw(err);
        })
    )
};