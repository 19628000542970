export interface HealthReportMappingProps {
  glu_fast: string;
  sgot: string;
  sgpt: string;
  chol_total: string;
  chol_good: string;
  chol_bad: string;
  triglycerides: string;
  creatinine: string;
  urea: string;
  uric_acid: string;
  haemo: string;
  pcv: string;
  rbc: string;
  mcv: string;
  mch: string;
  mchc: string;
  rdw: string;
  platelets: string;
  wbc: string;
  neutrophils: string;
  lymphocytes: string;
  monocytes: string;
  eosinophils: string;
  basophils: string;
}

export const itemCodeMapping = {
  glu_fast: {
    name: "Plasma Glucose, Fasting",
    unit: "mmol/L",
    refValue: ["<= 5.6 Normal", "5.7 - 6.9 Prediabetes", ">= 7.0 Diabetes"],
  },
  sgot: {
    name: "Aspartate Aminotransferase, SGOT/AST",
    unit: "U/L",
    refValue: ["< 34"],
  },
  sgpt: {
    name: "Alanine Aminotransferase, SGPT/ALT",
    unit: "U/L",
    refValue: ["< 45"],
  },
  chol_total: { name: "Cholesterol, Total", unit: "mmol/L", refValue:["Desirable: < 5.2", "Borderline: 5.2 - 6.2", "High: > 6.2"] },
  chol_good: {
    name: "Cholesterol, HDL (Good)",
    unit: "mmol/L",
    refValue: ["High Risk: < 1.0 mmol/L", "Low Risk: >= 1.6 mmol/L"],
  },
  chol_bad: {
    name: "Cholesterol, LDL (Bad) (Calculated)",
    unit: "mmol/L",
    refValue: [
      "Optimal: < 2.59",
      "Near/Above Optimal: 2.59 - 3.34",
      "Borderline High: 3.35 - 4.11",
      "High: 4.12 - 4.89",
      "Very High: >= 4.9",
    ],
  },
  triglycerides: {
    name: "Triglycerides",
    unit: "mmol/L",
    refValue: [
      "ATP III Levels",
      "Desirable: <1.7",
      "Borderline: 1.7 - 2.3",
      "High: 2.3 -5.6",
      "Very High: >= 5.6",
    ],
  },
  creatinine: {
    name: "Creatinine",
    unit: "mmol/L",
    refValue: ["(45.0 - 83.0)"],
  },
  urea: { name: "Urea", unit: "mmol/L", refValue: ["(2.1 - 7.2)"] },
  uric_acid: { name: "Uric Acid", unit: "mmol/L", refValue: ["(0.18 - 0.40)"] },
  haemo: {
    name: "Haemoglobin",
    unit: "g/dL",
    refValue: ["(11.3 - 15.0)"],
  },
  pcv: { name: "Haematocrit/P.C.V.", unit: "%", refValue: ["(34.0 - 45.0)"] },
  rbc: { name: "R.B.C.", unit: "x 10¹² /L", refValue: ["(3.80 - 4.99)"] },
  mcv: { name: "M.C.V.", unit: "fL", refValue: ["(82.0 - 98.0)"] },
  mch: { name: "M.C.H.", unit: "pg", refValue: ["(26.0 - 34.0)"] },
  mchc: { name: "M.C.H.C.", unit: "g/dL", refValue: ["(31.0 - 36.0)"] },
  rdw: { name: "R.D.W.", unit: "%", refValue: ["(12.0 - 17.0)"] },
  platelets: {
    name: "Platelets",
    unit: "x 10⁹ /L",
    refValue: ["(130.0 - 440.0)"],
  },
  wbc: { name: "W.B.C.", unit: "x 10⁹ /L", refValue: ["(3.9 - 10.0)"] },
  neutrophils: {
    name: "Neutrophils",
    unit1: "%",
    refValue1: ["(44.0 - 78.0)"],
    unit2: "x 10⁹ /L",
    refValue2: ["(1.70 - 7.70)"],
  },
  lymphocytes: {
    name: "Lymphocytes",
    unit1: "%",
    refValue1: ["(14.0 - 44.0)"],
    unit2: "x 10⁹ /L",
    refValue2: ["(0.90 - 3.30)"],
  },
  monocytes: {
    name: "Monocytes",
    unit1: "%",
    refValue1: ["(3.0 - 11.0)"],
    unit2: "x 10⁹ /L",
    refValue2: ["(0.10 - 0.70)"],
  },
  eosinophils: {
    name: "Eosinophils",
    unit1: "%",
    refValue1: ["(0.0 - 10.0)"],
    unit2: "x 10⁹ /L",
    refValue2: ["(0.00 - 0.70)"],
  },
  basophils: {
    name: "Basophils",
    unit1: "%",
    refValue1: ["(0.0 - 2.0)"],
    unit2: "x 10⁹ /L",
    refValue2: ["(0.00 - 0.10)"],
  },
};

export const initReportResults = {
  glu_fast: ["", ""],
  sgot: ["", ""],
  sgpt: ["", ""],
  chol_total: ["", ""],
  chol_good: ["", ""],
  chol_bad: ["", ""],
  triglycerides: ["", ""],
  creatinine: ["", ""],
  urea: ["", ""],
  uric_acid: ["", ""],
  haemo: ["", ""],
  pcv: ["", ""],
  rbc: ["", ""],
  mcv: ["", ""],
  mch: ["", ""],
  mchc: ["", ""],
  rdw: ["", ""],
  platelets: ["", ""],
  wbc: ["", ""],
  neutrophils: ["", ""],
  lymphocytes: ["", ""],
  monocytes: ["", ""],
  eosinophils: ["", ""],
  basophils: ["", ""],
};

export const hematologyItems1 = [
  "haemo",
  "pcv",
  "rbc",
  "mcv",
  "mch",
  "mchc",
  "rdw",
  "platelets",
  "wbc",
];

export const hematologyItems2 = [
  "neutrophils",
  "lymphocytes",
  "monocytes",
  "eosinophils",
  "basophils",
];

export const biochemicalItems = [
  "glu_fast",
  "sgot",
  "sgpt",
  "chol_total",
  "chol_good",
  "chol_bad",
  "triglycerides",
  "creatinine",
  "urea",
  "uric_acid",
];
