import { useState, useRef, useEffect, useContext } from "react";
import Cookie from "universal-cookie";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  Stack,
  Box,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  FormErrorMessage,
} from "@chakra-ui/react";

import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import { adminMobileBackRegister } from "../components/api";
import { useForm } from "react-hook-form";

interface MobileRegProps {
  phone: string;
  password: string;
  user: string;
}

interface AdminMobileBackRegisterResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

const MobileRegister = () => {
  // ---------------------------- Hooks start ----------------------------

  // -------------- Hooks to hold input value --------------
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  // -------------- Others --------------
  const [validationResponse, setValidationResponse] = useState(Object);
  const [isLoading, setIsLoading] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);
  const { errors, register, handleSubmit } = useForm();

  // ---------------------------- Hooks end ----------------------------

  const closeInputInvalidWindow = () => setInputInvalidWindow(false);
  const onSubmit = () => callApi();

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/home");
  }

  function closeSubmission() {
    setSubmittedWindow(false);
    history.push("/home");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  useEffect(() => {
    // Check cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, []);

  const callApi = async () => {
    const mobile_reg_props: MobileRegProps = {
      phone: phone,
      password: password,
      user: context.user,
    };

    try {
      setIsLoading(true);
      const mobileValidation: AdminMobileBackRegisterResponse =
        await adminMobileBackRegister(mobile_reg_props);
      if (mobileValidation.status === 400) {
        setValidationResponse(mobileValidation.data.result);
        setInputInvalidWindow(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSubmittedWindow(true);
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/upgrade">Mobile Register</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack spacing={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack pl="20%">
            <FormControl isRequired width="70%" isInvalid={errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+852" />
                <Input
                  name="phone"
                  placeholder="Phone number [0-9]"
                  onInput={(event: any) =>
                    setPhone("+852" + event.target.value)
                  }
                  ref={register({
                    pattern: /^[0-9]{8}$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.phone ? "block" : "none"}>
                <i>This input requires 8 numbers only.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired width="70%" isInvalid={errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password (min. 6 characters)"
                onInput={(event: any) => setPassword(event.target.value)}
                ref={register({
                  minLength: 6,
                })}
              />
              <FormErrorMessage display={errors.password ? "block" : "none"}>
                <i>This input requires a minimum length of 6.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired width="70%" isInvalid={errors.confirm}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                name="confirm"
                placeholder="Confirm password (min. 6 characters)"
                ref={register({
                  validate: (value: any) =>
                    value ===
                    (document.getElementById("password") as HTMLInputElement)
                      ?.value,
                })}
              />
              <FormErrorMessage display={errors.confirm ? "block" : "none"}>
                <i>This input doesn't match Password.</i>
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <br />

          <Box textAlign="center">
            <Button
              type="submit"
              isLoading={isLoading}
              loadingText="Submitting"
              colorScheme="teal"
            >
              Submit
            </Button>
          </Box>

          {/* -------------- Submission completed window -------------- */}
          <AlertDialog
            isOpen={submittedWindow || apiErrorWindow}
            motionPreset="slideInBottom"
            onClose={submittedWindow ? closeSubmission : closeApiErrorWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  {submittedWindow ? "SUCCESS" : "ERROR"}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() =>
                      submittedWindow
                        ? closeSubmission()
                        : closeApiErrorWindow()
                    }
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Input Invalid window -------------- */}
          <AlertDialog
            isOpen={inputInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeInputInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>
                    <b>{validationResponse}</b>.
                  </Text>
                  <Text>Please check your input and try again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeInputInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Cookies Timeout window -------------- */}
          <AlertDialog
            isOpen={cookieTimeoutWindow}
            motionPreset="slideInBottom"
            onClose={closeCookieTimeout}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Cookies timeout. Please login again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeCookieTimeout()}
                  >
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </form>
      </Stack>
    </Layout>
  );
};

export default MobileRegister;
