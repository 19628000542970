import { baseRequest } from "./baseRequest";

const url = "/admin/portal/users";

interface IProps {
    user: string
}

export const adminPortalUsersResponse = (props: IProps) => {
    return (
        baseRequest.get(url,
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            if (response.status === 200) {
                return (response.data.data)
            }
        })

        .catch((err) => {
            console.log(err);
        })
    );
}