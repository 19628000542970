import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Stack, Button } from "@chakra-ui/react";
import Layout from "../components/shared/Layout";
import Cookie from "universal-cookie";
import AppContext from "../components/shared/AppContext";

interface IProps {
    link: string,
    text: string
}

const pages = [
    {
        link: "/abpm_reports",
        text: "Abpm Reports"
    },
    {
        link: "/health_check_reports",
        text: "Health Check Reports"
    },
    // {
    //     link: "/usage",
    //     text: "Usage"
    // }
];

const Container = ({ link, text }: IProps) => {
    return (
        <RouterLink to = {link}>
            <Box bg = "#298879" paddingTop = "12px" height = "60px" rounded = "15px">
                {text}
            </Box>
        </RouterLink>
    )
}

const MedicalReports = () => {

    const context = useContext(AppContext);

    function removeCookie() {
        const cookie = new Cookie();
        cookie.remove("authenticate");
        context.setIsAuthenticate(false);
    }

    return (
        <Layout>
            <Stack 
                color = "#FFFFFF" 
                spacing = {6} 
                mx = "20%"
                textAlign = "center"
                fontSize = {["xl", "2xl", "2xl"]} 
                >
    
                {
                    pages.map((props, index) => (
                        <Container key = {index} {...props}/>
                    ))
                }

                {/* <RouterLink to = {"/"}>
                    <Button width = "100%" bg = "red.500" _hover = {{bg: "red.600"}} onClick = {removeCookie}>
                        Logout
                    </Button>
                </RouterLink> */}

            </Stack>
        </Layout>
    );
}

export default MedicalReports;