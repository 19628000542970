import { baseRequest } from "./baseRequest";

const url = "/v2/organization";

interface IProps {
    orgInput: string,
    chineseName: string,
    englishName: string,
    type: string,
    user: string
}

export const createOrganizationRequest = (props: IProps) => {
    return baseRequest.post(url, {
        organizer: props.orgInput,
        organization_uid: props.orgInput,
        name_l1: props.chineseName,
        name_l2: props.englishName,
        type: props.type
    },
    {
        params: {
            role: props.user
        },
        
        headers: {
            'Content-Type': 'application/json',
            'Token': process.env.REACT_APP_API_KEY
        }
    })

    .then((response) => {
        return response.data;
    })
    .catch((err) =>{
        throw (err);
    })
};