import { baseRequest } from "./baseRequest";

const url = "/admin/auth/mobile/upgrade"

interface IProps {
    phone:string,
    user: string
}

export const adminMobileUpgrade = (props:IProps) => {
    return baseRequest.post(url, {
            mobile: props.phone
        },
        {
            params: {
                role: props.user
            },

            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return (response.data);
        })

        .catch((err) => {
            if (err.response.status === 400)
                return err.response;
            else
                throw (err);
        })
    ;
}
