import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  useEditableControls,
  ButtonGroup,
  IconButton,
  Flex,
  Editable,
  EditablePreview,
  Input,
  EditableInput,
  HStack,
} from "@chakra-ui/react";
import { useState } from "react";

interface HealthReportEditableProps {
  itemCode: string;
  itemName: string;
  reportResults: any;
  setReportResults: React.Dispatch<React.SetStateAction<string>>;
  resultPosition: number;
}

export function HealthReportEditable(props: HealthReportEditableProps) {
  /* Here's a custom control */
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Submit Button"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancel Button"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          key="edit"
          w="20px"
          aria-label="Edit Button"
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  const value = props.reportResults[props.itemCode];

  const [colorState, setColorState] = useState(
    checkValidResult(
      props.reportResults[props.itemCode] != null
        ? props.reportResults[props.itemCode][props.resultPosition]
        : "",
    )
      ? "black"
      : "red",
  );

  return (
    <Editable
      w="200px"
      key={props.itemCode}
      textAlign="center"
      placeholder="No value"
      defaultValue={
        props.reportResults[props.itemCode] != null
          ? props.reportResults[props.itemCode][props.resultPosition]
          : ""
      }
      fontSize="lg"
      isPreviewFocusable={false}
      onSubmit={(nextValue) => {
        const reportResults = props.reportResults;
        const itemCode = props.itemCode;
        if (reportResults[itemCode] != null) {
          reportResults[itemCode][props.resultPosition] = nextValue;
        } else {
          if (Object.keys(reportResults).includes(itemCode) == false) {
            reportResults[itemCode] = ["", ""];
            reportResults[itemCode][props.resultPosition] = nextValue;
          }
        }
        props.setReportResults(reportResults);
        // console.log(props.reportResults);

        checkValidResult(
          props.resultPosition == null
            ? props.reportResults[props.itemCode]
            : props.reportResults[props.itemCode][props.resultPosition],
        )
          ? setColorState("black")
          : setColorState("red");
      }}
    >
      <HStack spacing="20px">
        <EditablePreview w="180px" color={colorState} />
        {/* Here is the custom input */}
        <Input as={EditableInput} w="100px" />
        <EditableControls />
      </HStack>
    </Editable>
  );
}

const checkValidResult = (result: string) => {
  const doubleCheck = new RegExp("^[+-]?([0-9]*[.])?[0-9]+$");

  if (result == null || result == "" || !doubleCheck.test(result)) return false;
  else return true;
};
