import { baseRequest } from "./baseRequest";

const url = "/v3/auth";

interface IProps {
    phone: string,
    password: string,
    id: number,
    user: string
}

export const centreAppRegister = (props:IProps) => {
    return (
        baseRequest.post(url, {
            phone: props.phone,
            password: props.password,
            organization_id: props.id
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return (response.data);
        })

        .catch((err) => {
            console.log(err);
        })
    );
};