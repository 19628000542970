import { localRequest, baseRequest } from "./baseRequest";

const url = "v2/abpm/reports/upload";

interface IProps {
  mobile: string;
  excel_file: File;
  pdf_file: File;
}

export const abpmApi = (props: IProps) => {
  var formData = new FormData();

  formData.append("mobile", props.mobile);
  formData.append("excel_file", props.excel_file);
  formData.append("pdf_file", props.pdf_file);

  return (
    // localRequest.post(url,
    baseRequest
      .post(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Token: process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return err.response;
        } else {
          throw err;
        }
      })
  );
};
