import { useContext } from "react";
import Layout from "../components/shared/Layout";
import { Box, Stack } from "@chakra-ui/react";
import { useHistory } from "react-router";
import AppContext from "../components/shared/AppContext";

interface IProps {
    text: string
}

const member = [
    {
        text: "Chris"
    },
    {
        text: "Edwin"
    },
    {
        text: "Lemy"
    },
    {
        text: "Richard"
    },
    {
        text: "Kelly"
    }
];

const Staff = () => {

    const history = useHistory();
    const context = useContext(AppContext);

    function passText(text:string) {
        context.setUser(text);
        history.push("/home");
    }

    const Container = ({ text }: IProps) => {
        return (
            <Box as = "button" bg = "#298879" height = "60px" rounded = "15px" onClick = {() => passText(text)}>
                {text}
            </Box>
        )
    }

    return (
        <Layout>
            <Stack
                color = "#FFFFFF" 
                spacing = {6} 
                mx = "20%"
                textAlign = "center"
                fontSize = {["xl", "2xl", "2xl"]}
            >
                {
                    member.map((props, index) => (
                        <Container key = {index} {...props} />
                    ))
                }
            </Stack>
            
        </Layout>
    )
}

export default Staff;