import { baseRequest } from "./baseRequest";

const url = "/admin/portal/validate";

type syncDataBy = "p" | "c";

interface Centre {
    userName: string,
    password: string,
    phone: string,
    orgInput: string,
    syncDataBy: syncDataBy,
    user: string
}

interface Promotion {
    userName: string,
    password: string,
    promoPrefix: string,
    orgInput: string,
    syncDataBy: syncDataBy,
    user: string
}

const createPortalValidationCentre = (props:Centre) => {
    return (
        baseRequest.post(url, {
            user_name: props.userName,
            password: props.password,
            phone: props.phone,
            organizer: props.orgInput,
            sync_data_by: "c"
        },
        {
            params: {
                role: props.user
            },

            headers: {
                "Content-Type" : "application/json",
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((res) => {
            return res.data;
        })

        .catch((err) => {
            if (err.response.status === 400)
                return err.response;
            else
                throw (err);
        })
    )
}

const createPortalValidationPromotion = (props:Promotion) => {
    console.log(process.env.REACT_APP_API_HOST);
    return (
        baseRequest.post(url, {
            user_name: props.userName,
            password: props.password,
            promotion_code_prefix: props.promoPrefix,
            organizer: props.orgInput,
            sync_data_by: "p"
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                "Content-Type" : "application/json",
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((res) => {
            return res.data;
        })

        .catch((err) => {
            if (err.response.status === 400)
                return err.response;
            else
                throw (err);
        })
    )
}

export { createPortalValidationCentre, createPortalValidationPromotion };