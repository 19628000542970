import { Center, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { HealthReportEditable } from "./healthReportEditable";

export const brFormItemContainer = (
  itemCode: string,
  itemMapJson: any,
  reportResults: any,
  setReportResults: React.Dispatch<React.SetStateAction<string>>,
) => {
  return (
    <Center key={itemCode}>
      <HStack direction={"row"} spacing="20px">
        <Heading w="200px" size="md">
          {itemMapJson[itemCode].name}
        </Heading>
        <HealthReportEditable
          itemCode={itemCode}
          itemName={itemMapJson[itemCode].name}
          reportResults={reportResults}
          setReportResults={setReportResults}
          resultPosition={0}
        />
        <Heading w="120px" size="md">
          {itemMapJson[itemCode].unit}
        </Heading>
        <Text w="250px">{itemMapJson[itemCode].refValue}</Text>
      </HStack>
    </Center>
  );
};

export const biochemFormItemContainer = (
  itemCode: string,
  itemMapJson: any,
  reportResults: any,
  setReportResults: React.Dispatch<React.SetStateAction<string>>,
) => {
  const refValue: string[] = itemMapJson[itemCode].refValue;
  return (
    <Center key={itemCode}>
      <HStack direction={"row"} spacing="20px" margin="5px">
        <Heading w="300px" size="md">
          {itemMapJson[itemCode].name}
        </Heading>
        <HealthReportEditable
          itemCode={itemCode}
          itemName={itemMapJson[itemCode].name}
          reportResults={reportResults}
          setReportResults={setReportResults}
          resultPosition={0}
        />
        <Heading w="100px" size="md">
          {itemMapJson[itemCode].unit}
        </Heading>
        <VStack spacing="2px" w="400px">
          {refValue.map((item, index) => {
            return (
              <Text w="300px" key={index}>
                {item}
              </Text>
            );
          })}
        </VStack>
      </HStack>
    </Center>
  );
};

export const brForm2ItemsContainer = (
  itemCode: string,
  itemMapJson: any,
  reportResults: any,
  setReportResults: React.Dispatch<React.SetStateAction<string>>,
) => {
  return (
    <Center key={itemCode}>
      <HStack direction={"row"} spacing="20px">
        <Heading w="180px" size="md">
          {itemMapJson[itemCode].name}
        </Heading>
        <HealthReportEditable
          itemCode={itemCode}
          itemName={itemMapJson[itemCode].name}
          reportResults={reportResults}
          setReportResults={setReportResults}
          resultPosition={0}
        />
        <Heading w="80px" size="md">
          {itemMapJson[itemCode].unit1}
        </Heading>
        <Text w="100px">{itemMapJson[itemCode].refValue1}</Text>
        <HealthReportEditable
          itemCode={itemCode}
          itemName={itemMapJson[itemCode].name}
          reportResults={reportResults}
          setReportResults={setReportResults}
          resultPosition={1}
        />
        <Heading w="100px" size="md">
          {itemMapJson[itemCode].unit2}
        </Heading>
        <Text w="100px">{itemMapJson[itemCode].refValue2}</Text>
      </HStack>
    </Center>
  );
};
