import { baseRequest } from "./baseRequest";

const url = "/admin/auth/login";

interface IProps {
    userName: string,
    password: string
}

export const adminLogin = (props: IProps) => {
    return (
        baseRequest.post(url, {
            user_name: props.userName,
            password: props.password
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return (response.data);
        })

        .catch((err) => {
            if (err.response.status === 400) {
                return err.response;
            }
            else {
                throw (err)
            }
        })
    );
}