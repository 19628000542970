import { useState, useRef, useEffect, useContext, SetStateAction } from "react";
import Cookie from "universal-cookie";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  Stack,
  Box,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FileUploader } from "../components/FileUploader/fileUploader";
import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import { abpmApi } from "../components/api/";
import { useForm } from "react-hook-form";
import path from "path";

interface AbpmUploadProps {
  mobile: string;
  excel_file: any;
  pdf_file: any;
}

interface AbpmUploadResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

const AbpmReport = () => {
  // ---------------------------- Hooks start ----------------------------

  // -------------- Hooks to hold input value --------------
  const [phone, setPhone] = useState("");
  const [files, setFiles] = useState([]);

  // -------------- Others --------------
  const [validationResponse, setValidationResponse] = useState(Object);
  const [isLoading, setIsLoading] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
  const [fileInvalidWindow, setFileInvalidWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);
  const { errors, register, handleSubmit } = useForm();

  // ---------------------------- Hooks end ----------------------------

  const closeInputInvalidWindow = () => setInputInvalidWindow(false);
  const closeFileInvalidWindow = () => setFileInvalidWindow(false);
  // const onSubmit = () => {};
  const onSubmit = () => callApi();

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/medical_reports");
  }

  function closeSubmission() {
    setSubmittedWindow(false);
    history.push("/medical_reports");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  useEffect(() => {
    // Check cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, []);

  useEffect(() => {
    if (files.length !== 0) {
      if (files.length !== 2) {
        setFileInvalidWindow(true);
      } else {
        console.log(files);
        var filename1: string = files[0]["name"];
        var filename2: string = files[1]["name"];
        var ext1 = filename1.split(".").pop();
        var ext2 = filename2.split(".").pop();
        if (ext1 === ext2) {
          console.log("File extension is same");
          setFileInvalidWindow(true);
        }
      }
    }
  }, [files]);

  const sendDataToParent = (files: any) => {
    setFiles(files);
  };

  const callApi = async () => {
    var pdf_file: any;
    var excel_file: any;
    var name: string = files[0]["name"];

    if (name.split(".").pop() === "pdf") {
      pdf_file = files[0];
      excel_file = files[1];
    } else {
      excel_file = files[0];
      pdf_file = files[1];
    }

    const abpm_upload_props: AbpmUploadProps = {
      mobile: phone,
      excel_file: excel_file,
      pdf_file: pdf_file,
    };

    try {
      setIsLoading(true);
      const abpmUpload: AbpmUploadResponse = await abpmApi(abpm_upload_props);
      if (abpmUpload.status === 400) {
        setValidationResponse(abpmUpload.data.result);
        setInputInvalidWindow(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSubmittedWindow(true);
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <RouterLink to={"/medical_reports"}>
            <BreadcrumbLink>Medical Reports</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/abpm_reports">ABPM Reports</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Stack spacing={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack pl="20%">
            <FormControl isRequired width="70%" isInvalid={errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+852" />
                <Input
                  name="phone"
                  placeholder="Phone number [0-9]"
                  onInput={(event: any) =>
                    setPhone("+852" + event.target.value)
                  }
                  ref={register({
                    pattern: /^[0-9]{8}$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.phone ? "block" : "none"}>
                <i>This input requires 8 numbers only.</i>
              </FormErrorMessage>
              <br />
              <label>Please Upload the Excel and PDF Files </label>
              <br />
              <FileUploader sendDataToParent={sendDataToParent} />
              <br />
            </FormControl>
          </Stack>

          <Box textAlign="center">
            <Button
              type="submit"
              isLoading={isLoading}
              loadingText="Submitting"
              colorScheme="teal"
            >
              Submit
            </Button>
          </Box>

          {/* -------------- Submission completed window -------------- */}
          <AlertDialog
            isOpen={submittedWindow || apiErrorWindow}
            motionPreset="slideInBottom"
            onClose={submittedWindow ? closeSubmission : closeApiErrorWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  {submittedWindow ? "SUCCESS" : "ERROR"}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() =>
                      submittedWindow
                        ? closeSubmission()
                        : closeApiErrorWindow()
                    }
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Input Invalid window -------------- */}
          <AlertDialog
            isOpen={inputInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeInputInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>
                    <b>{validationResponse}</b>.
                  </Text>
                  <Text>Please check your input and try again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeInputInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- File Invalid Select window -------------- */}
          <AlertDialog
            isOpen={fileInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeFileInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Invalid File Selection</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>
                    <b>You need to select two files</b>
                  </Text>
                  <Text>.pdf and .xls are required for the upload</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeFileInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Cookies Timeout window -------------- */}
          <AlertDialog
            isOpen={cookieTimeoutWindow}
            motionPreset="slideInBottom"
            onClose={closeCookieTimeout}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Cookies timeout. Please login again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeCookieTimeout()}
                  >
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </form>
      </Stack>
    </Layout>
  );
};

export default AbpmReport;
