import "./style.css";

export const FileUploader = ({ sendDataToParent }: any) => {
  const onInputChange = (e: any) => {
    sendDataToParent(e.target.files);
  };

  return (
    <div
      className="form-group files"
      //   style={{ width: "100%", alignContent: "center" }}
    >
      <input
        type="file"
        onChange={onInputChange}
        className="form-control"
        multiple
      />
    </div>
  );
};
