import { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import Cookie from "universal-cookie";
import { Box, Stack, FormControl, Input, Button, AlertDialog, AlertDialogOverlay, 
        AlertDialogHeader, AlertDialogBody, AlertDialogFooter, AlertDialogContent, Text } from "@chakra-ui/react";


import Layout from "../components/shared/Layout";
import { adminLogin } from "../components/api"
import AppContext from "../components/shared/AppContext";

interface ValidationProps {
    userName: string,
    password: string
}

interface AdminLoginResponse {
    async(): Promise<string>,
    status: number,
    data: {
        result: string
    }
}

const Login = () => {
    // ---------------------------- Hooks start ----------------------------

    // -------------- Hooks to hold input value --------------
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    // -------------- Hooks to check if input is invalid --------------
    const [inputInvalid, setInputInvalid] = useState({
        username: true,
        password: true
    })

    // -------------- Others --------------
    const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiErrorWindow, setApiErrorWindow] = useState(false);
    const [validationResponse, setValidationResponse] = useState("");

    const initialRef = useRef<any>();
    const history = useHistory();
    const context = useContext(AppContext);

    // ---------------------------- Hooks end ----------------------------

    const closeInputInvalidWindow = () => setInputInvalidWindow(false);
    const closeApiErrorWindow = () => setApiErrorWindow(false);

    function handleInput(event:any){
        if (event.target.id === "username" && event.target.value.length < 6) {
            event.target.style.color = "red";
            setInputInvalid({
                ...inputInvalid,
                username: true
            });
        }
        else if (event.target.id === "password" && event.target.value.length < 6) {
            event.target.style.color = "red";
            setInputInvalid({
                ...inputInvalid,
                password: true
            });
        }
        else {
            event.target.style.color = "black";
            switch (event.target.id) {
                case "username":
                    setUserName(event.target.value)
                    setInputInvalid({
                        ...inputInvalid,
                        username: false
                    });
                    break;
                case "password":
                    setPassword(event.target.value)
                    setInputInvalid({
                        ...inputInvalid,
                        password: false
                    });
                    break;
            }
        }
    }

    function inputIsInvalid() {
        return (inputInvalid.password || inputInvalid.username);
    }

    const callApi = async() => {
        const validation_props: ValidationProps = {
            userName: userName,
            password: password
        }

        try {
            setIsLoading(true);
            const adminValidation: AdminLoginResponse = await adminLogin(validation_props);
            if (adminValidation.status === 400) {
                setValidationResponse(adminValidation.data.result);
                setInputInvalidWindow(true);
                setIsLoading(false);
            }
            else {
                const cookie = new Cookie();
                cookie.set("authenticate", "user", {path: "/", maxAge: 1800});
                context.setIsAuthenticate(true);
                history.push("/staff");
            }
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
            setApiErrorWindow(true);
        }
    }

    return (
        <Layout>
            <Stack spacing = {4} alignItems = "center">
                <FormControl width = "40%">
                    <Input id = "username" placeholder = "Username" onChange = {(event) => handleInput(event)}/>
                </FormControl>
                <FormControl width = "40%">
                    <Input type = "password" id = "password" placeholder = "Password" onChange = {(event) => handleInput(event)}/>
                </FormControl>
                <Box textAlign = "center">
                    <Button 
                        disabled = {inputIsInvalid()}
                        isLoading = {isLoading}
                        loadingText = "Logging in..."
                        colorScheme = "teal"
                        onClick = {() => callApi()}>
                        Login
                    </Button>

                    <Box 
                        display = {inputIsInvalid() ? "block" : "none"} 
                        color = "#718096" 
                        fontSize = "sm">
                        <Text><i>Please input the following field(s) accordingly</i></Text>
                        <Text display = {inputInvalid.username ? "block" : "none"}><i>Username ~ Minimum length of <b>6</b></i></Text>
                        <Text display = {inputInvalid.password ? "block" : "none"}><i>Password ~ Minimum length of <b>6</b></i></Text>
                    </Box>
                </Box>
            </Stack>

            {/* -------------- Input Invalid window -------------- */}
            <AlertDialog
                isOpen = {inputInvalidWindow}
                motionPreset = "slideInBottom"
                onClose = {closeInputInvalidWindow}
                leastDestructiveRef = {initialRef}
                isCentered>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                            <AlertDialogBody>
                                <Text><b>{validationResponse}</b>.</Text>
                                <Text>Please check your input and try again.</Text>
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref = {initialRef} colorScheme = "teal" onClick = {() => closeInputInvalidWindow()}>
                                    Close
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
            </AlertDialog>
            
            {/* -------------- API Error window -------------- */}
            <AlertDialog
                isOpen = {apiErrorWindow}
                motionPreset = "slideInBottom"
                onClose = {closeApiErrorWindow}
                leastDestructiveRef = {initialRef}
                isCentered>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader>ERROR</AlertDialogHeader>
                            <AlertDialogFooter>
                                <Button ref = {initialRef} colorScheme = "teal" onClick = {() => closeApiErrorWindow()}>
                                    Close
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
            </AlertDialog>
        </Layout>
    );
}

export default Login;