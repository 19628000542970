import { useEffect, useRef, useState, useContext } from "react";
import Cookie from "universal-cookie";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Button,
  Box,
  Select,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  FormErrorMessage,
} from "@chakra-ui/react";

import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import {
  createOrganizationRequest,
  centreAppRegister,
  createPortalValidationCentre,
  portalRegister,
  adminPortalResetPassword,
  adminPatchOrganization,
} from "../components/api";
import { useForm } from "react-hook-form";

type syncDataBy = "p" | "c";

// -------------- interface for input fields --------------
interface OrgProps {
  orgInput: string;
  chineseName: string;
  englishName: string;
  type: string;
  user: string;
}

interface PortalProps {
  orgInput: string;
  id: number;
  userName: string;
  password: string;
  syncDataBy: syncDataBy;
  user: string;
}

interface ResetPasswordProps {
  userName: string;
  password: string;
  user: string;
}

interface PatchOrganizationProps {
  chineseName: string;
  englishName: string;
  orgInput: string;
  type: string;
  user: string;
}

interface ValidationProps {
  orgInput: string;
  password: string;
  phone: string;
  userName: string;
  syncDataBy: syncDataBy;
  user: string;
}

interface CentreProps {
  phone: string;
  password: string;
  id: number;
  user: string;
}

// -------------- interface for api calls --------------
interface PortalRegisterResponse {
  async(): Promise<string>;
}

interface CreatePortalValidationCentreResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: {
      [key: string]: string;
    };
  };
}

interface AdminPortalResetPasswordResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

interface AdminPatchOrganizationResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

interface CentreAppRegisterResponse {
  async(): Promise<string>;
}

interface CreateOrganizationRequestResponse {
  async(): Promise<string>;
  data: {
    organization_id: number;
  };
}

const Centre = () => {
  // ---------------------------- Hooks start ----------------------------

  // -------------- Hooks to hold input value --------------
  const [orgInput, setOrgInput] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // -------------- Others --------------
  const [validationResponse, setValidationResponse] = useState(Object);
  const [patchResponse, setPatchResponse] = useState(Object);
  const [resetResponse, setResetResponse] = useState(Object);
  const [isLoading, setIsLoading] = useState(false);

  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);

  const [isUpdateDetails, setIsUpdateDetails] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [updateDetailsData, setUpdateDetailsData] = useState(Object);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);
  const { errors, register, handleSubmit } = useForm();

  // ---------------------------- Hooks end ----------------------------

  const closeInputInvalidWindow = () => setInputInvalidWindow(false);
  const onSubmit = () => callApi();

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/home");
  }

  function closeSubmission() {
    setSubmittedWindow(false);
    history.push("/home");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  useEffect(() => {
    // check if history has state data
    const fromDetails = history.location.state;
    if (fromDetails) {
      if (fromDetails[1] === "Update Details") {
        setIsUpdateDetails(true);
        setChineseName(fromDetails[0]["name_l1"]);
        setEnglishName(fromDetails[0]["name_l2"]);
        setType(fromDetails[0]["type"]);
      } else if (fromDetails[1] === "Reset Password") {
        setIsResetPassword(true);
        setUserName(fromDetails[0]["user_name"]);
      }
      setOrgInput(fromDetails[0]["organizer"]);
      setUpdateDetailsData(fromDetails[0]);
    }

    // Check cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, [history.location.state]);

  const callApi = async () => {
    const org_props: OrgProps = {
      orgInput: orgInput,
      chineseName: chineseName,
      englishName: englishName,
      type: type,
      user: context.user,
    };
    const portal_props: PortalProps = {
      orgInput: orgInput,
      id: 0,
      userName: userName,
      password: password,
      syncDataBy: "c",
      user: context.user,
    };
    const validation_props: ValidationProps = {
      orgInput: orgInput,
      password: password,
      phone: phone,
      userName: userName,
      syncDataBy: "c",
      user: context.user,
    };
    const centre_props: CentreProps = {
      phone: phone,
      password: password,
      id: 0,
      user: context.user,
    };
    const reset_password_props: ResetPasswordProps = {
      userName: userName,
      password: password,
      user: context.user,
    };
    const patch_organization_props: PatchOrganizationProps = {
      chineseName: chineseName,
      englishName: englishName,
      orgInput: orgInput,
      type: type,
      user: context.user,
    };

    try {
      setIsLoading(true);
      if (isUpdateDetails) {
        const patchOrganizationResponse: AdminPatchOrganizationResponse =
          await adminPatchOrganization(patch_organization_props);
        if (patchOrganizationResponse.status === 400) {
          setPatchResponse(patchOrganizationResponse.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      } else if (isResetPassword) {
        const resetPasswordResponse: AdminPortalResetPasswordResponse =
          await adminPortalResetPassword(reset_password_props);
        if (resetPasswordResponse.status === 400) {
          setResetResponse(resetPasswordResponse.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      } else {
        const portalValidation: CreatePortalValidationCentreResponse =
          await createPortalValidationCentre(validation_props);
        if (portalValidation.status === 400) {
          setValidationResponse(portalValidation.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          const orgReq: CreateOrganizationRequestResponse =
            await createOrganizationRequest(org_props);
          portal_props.id = orgReq.data.organization_id;
          centre_props.id = orgReq.data.organization_id;
          const portalReg: PortalRegisterResponse = await portalRegister(
            portal_props,
          );
          const centreReg: CentreAppRegisterResponse = await centreAppRegister(
            centre_props,
          );
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem
          display={isUpdateDetails || isResetPassword ? "inline" : "none"}
        >
          <RouterLink to={"/details"}>
            <BreadcrumbLink>Details</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/centre">Centre</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* -------------- Organizer starts -------------- */}
          <Heading size="lg">Organizer</Heading>
          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.organizer}
              isDisabled={isUpdateDetails || isResetPassword ? true : false}
              isRequired
            >
              <FormLabel>Organizer ID (For searching in portal)</FormLabel>
              <Input
                name="organizer"
                placeholder="Organizer's ID (eg. abc01)"
                onInput={(event: any) => setOrgInput(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["organizer"]
                    : ""
                }
                ref={register({
                  minLength: 5,
                })}
              />
              <FormErrorMessage display={errors.organizer ? "block" : "none"}>
                <i>This input requires a minimum length of 5.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl isDisabled>
              <FormLabel>UID (No need to fill in)</FormLabel>
              <Input
                value={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["organizer"]
                    : orgInput
                }
              />
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.chinesename}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Name (Chinese)</FormLabel>
              <Input
                name="chinesename"
                placeholder="Name in Chinese (e.g.聖公會xxx中心)"
                onInput={(event: any) => setChineseName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["name_l1"]
                    : ""
                }
                ref={register({
                  required: true,
                })}
              />
            </FormControl>
            <FormControl
              isInvalid={errors.englishname}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Name (English)</FormLabel>
              <Input
                name="englishname"
                placeholder="Name in English"
                onInput={(event: any) => setEnglishName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["name_l2"]
                    : ""
                }
                ref={register({
                  required: true,
                })}
              />
            </FormControl>
          </Stack>

          {/* -------------- Centre starts -------------- */}
          <Heading size="lg">Centre</Heading>
          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.phone}
              isRequired
              isDisabled={isResetPassword || isUpdateDetails ? true : false}
            >
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+852" />
                <Input
                  name="phone"
                  placeholder="Phone number [0-9]"
                  onInput={(event: any) =>
                    setPhone("+852" + event.target.value)
                  }
                  defaultValue={
                    isUpdateDetails || isResetPassword
                      ? updateDetailsData["mobile"]
                      : ""
                  }
                  ref={register({
                    pattern: /^[0-9]{8}$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.phone ? "block" : "none"}>
                <i>This input requires 8 numbers only.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.type}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                placeholder="Select type"
                onInput={(event: any) => setType(event.target.value)}
                value={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["type"]
                    : type
                }
                ref={register({
                  required: true,
                })}
              >
                <option>Clinic</option>
                <option>Centre</option>
                <option>Internal</option>
                <option>Commercial</option>
              </Select>
            </FormControl>
          </Stack>

          {/* -------------- Create Account starts -------------- */}
          <Heading size="lg">Create Account</Heading>
          <Stack pl="25%">
            <FormControl
              isInvalid={errors.username}
              isRequired
              isDisabled={isUpdateDetails ? true : false}
              width="70%"
            >
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                placeholder="Username (min. 6 characters)"
                onInput={(event: any) => setUserName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["user_name"]
                    : ""
                }
                ref={register({
                  minLength: 6,
                })}
              />
              <FormErrorMessage display={errors.username ? "block" : "none"}>
                <i>This input requires a minimum length of 6.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.password}
              isRequired
              isDisabled={isUpdateDetails ? true : false}
              width="70%"
            >
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password (min. 6 characters)"
                onInput={(event: any) => setPassword(event.target.value)}
                ref={register({
                  minLength: 6,
                })}
              />
              <FormErrorMessage display={errors.password ? "block" : "none"}>
                <i>This input requires a minimum length of 6.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.confirm}
              isRequired
              isDisabled={isUpdateDetails ? true : false}
              width="70%"
            >
              <FormLabel>Confirm password</FormLabel>
              <Input
                type="password"
                name="confirm"
                placeholder="Confirm password (min. 6 characters)"
                ref={register({
                  validate: (value: any) =>
                    value ===
                    (document.getElementById("password") as HTMLInputElement)
                      ?.value,
                })}
              />
              <FormErrorMessage display={errors.confirm ? "block" : "none"}>
                <i>This input doesn't match Password.</i>
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Box textAlign="center">
            <Button
              type="submit"
              isLoading={isLoading}
              loadingText="Submitting"
              colorScheme="teal"
            >
              Submit
            </Button>
          </Box>

          {/* -------------- Submission completed window -------------- */}
          <AlertDialog
            isOpen={submittedWindow || apiErrorWindow}
            motionPreset="slideInBottom"
            onClose={submittedWindow ? closeSubmission : closeApiErrorWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  {submittedWindow ? "SUCCESS" : "ERROR"}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() =>
                      submittedWindow ? closeSubmission() : closeSubmission()
                    }
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Input Invalid window -------------- */}
          <AlertDialog
            isOpen={inputInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeInputInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                <AlertDialogBody>
                  {isResetPassword ? (
                    <Text>
                      <b>{resetResponse}</b>
                    </Text>
                  ) : (
                    <div></div>
                  )}
                  {isUpdateDetails ? <Text>{patchResponse}</Text> : <div></div>}
                  {isResetPassword || isUpdateDetails ? (
                    <div></div>
                  ) : (
                    Object.keys(validationResponse).map(function (key, index) {
                      return (
                        <Text key={index}>
                          <b>{validationResponse[key]}</b>.
                        </Text>
                      );
                    })
                  )}
                  <Text>Please check your input and try again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeInputInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Cookies Timeout window -------------- */}
          <AlertDialog
            isOpen={cookieTimeoutWindow}
            motionPreset="slideInBottom"
            onClose={closeCookieTimeout}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Cookies timeout. Please login again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeCookieTimeout()}
                  >
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
      </form>
    </Layout>
  );
};

export default Centre;
