import { localRequest, baseRequest } from "./baseRequest";

const submitUrl = "v2/health_check/reports/upload";
const scanUrl = "v2/health_check/reports/scan-report";

export interface IScanHealthReportProps {
  mobile: string;
  report_date: string;
  pdf_file: any;
}

export interface ISubmitHealthReportProps {
  mobile: string;
  report_date: string;
  data: any;
  pdf_file: any;
}

export interface HealthCheckReportScanResponse {
  async(): Promise<string>;
  status: number;
  messages: string;
  data: {
    result: string;
  };
}

export const healthCheckReportSubmitApi = (props: ISubmitHealthReportProps) => {
  var formData = new FormData();

  //   const data = {
  //     mobile: props.mobile,
  //     report_date: props.report_date,
  //     data: props.data,
  //   };

  formData.append("mobile", props.mobile);
  formData.append("report_date", props.report_date);
  formData.append("data", JSON.stringify(props.data));
  formData.append("pdf_file", props.pdf_file);

  return baseRequest.post(submitUrl, 
  // localRequest
  //   .post(
  //     submitUrl,
      
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Token: process.env.REACT_APP_API_KEY,
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response;
      } else {
        throw err;
      }
    });
};

export const healthCheckReportScanApi = (props: IScanHealthReportProps) => {
  var formData = new FormData();

  formData.append("mobile", props.mobile);
  formData.append("pdf_file", props.pdf_file);

  console.log(formData);

  return (
    baseRequest
      // localRequest
      //   .post(
      //     scanUrl,

      .post(scanUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          Token: process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return err.response;
        } else {
          throw err;
        }
      })
  );
};
