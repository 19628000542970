// This component is necessary to maintain isAuthenticate as a Global Hook.
import React from "react";

interface ContextInterface {
    isAuthenticate: boolean,
    user: string,
    setIsAuthenticate: React.Dispatch<React.SetStateAction<boolean>>,
    setUser: React.Dispatch<React.SetStateAction<string>>
}

const AppContext = React.createContext<ContextInterface>({
    isAuthenticate: false,
    setIsAuthenticate: () => {},
    user: "",
    setUser: () => {}
});

export default AppContext;