import { baseRequest } from "./baseRequest";

const url = "/v2/organization/code"

interface IProps {
    orgInput: string,
    promoPrefix: string,
    startDate: string,
    endDate: string,
    quota: number,
    dataConsent: number,
    premiumUpgrade: number,
    discount: number,
    upgradePeriod: number,
    user: string
}

export const createPromotionCodePrefixRequest = (props:IProps) => {
    return (
        baseRequest.post(url, {
            organizer: props.orgInput,
            promotion_code_prefix: props.promoPrefix,
            promotion_code: null,
            start_date: props.startDate,
            end_date: props.endDate,
            quota: props.quota,
            for_data_consent: props.dataConsent,
            for_family_upgrade: props.premiumUpgrade,
            for_discount: props.discount,
            for_upgrade_period: props.upgradePeriod
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return response.data;
        })

        .catch ((err) => {
            throw(err);
        })
    );
    
};