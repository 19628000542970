import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import Cookie from "universal-cookie";
import PDFMerger from "pdf-merger-js/browser";
import { useForm } from "react-hook-form";
import {
  biochemicalItems,
  hematologyItems1,
  hematologyItems2,
  initReportResults,
  itemCodeMapping,
} from "../components/common/healthReportMapping";
import {
  FormControl,
  FormLabel,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Button,
  Stack,
  Heading,
  Input,
  Textarea,
  FormErrorMessage,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  Text,
  Center,
  HStack,
  InputGroup,
  InputLeftAddon,
  Spacer,
} from "@chakra-ui/react";
import { FileUploader } from "../components/FileUploader/fileUploader";
import {
  healthCheckReportScanApi,
  HealthCheckReportScanResponse,
  healthCheckReportSubmitApi,
  ISubmitHealthReportProps,
  IScanHealthReportProps,
} from "../components/api/";
import { report, send } from "process";
import { HealthReportEditable } from "../components/common/healthReportEditable";
import {
  biochemFormItemContainer,
  brForm2ItemsContainer,
  brFormItemContainer,
} from "../components/common/healthReportContainers";

// -------------- interface for input fields --------------
var itemMapJson: any;

const HealthCheckReport = () => {
  // ---------------------------- Hooks start ----------------------------
  // -------------- Hooks to hold input value --------------
  const [mobile, setMobile] = useState("");
  const [reportDate, setReportDate] = useState(
    new Date().toISOString().slice(0, 10),
  );
  // const [reportData, setReportData] = useState([]);
  const [files, setFiles] = useState([]);
  const [pdfReport, setPdfReport] = useState<Blob>();
  const [isScanned, setIsScanned] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const buttonText = isScanned ? "Submit Report" : "Scan Report";

  const [reportResults, setReportResults] = useState(initReportResults);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const externalUrl = watch("externalUrl");

  // -------------- Others --------------
  const [validationResponse, setValidationResponse] = useState(Object);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationWindow, setConfirmationWindow] = useState(false);
  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);

  // ---------------------------- Hooks end ----------------------------
  const closeConfirmationWindow = () => setConfirmationWindow(false);
  const closeInputInvalidWindow = () => setInputInvalidWindow(false);
  const onSubmit = () => callScanReportApi();

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/medical_reports");
  }

  function closeSubmissionWindow() {
    setSubmittedWindow(false);
    if (isUploaded == true) {
      history.push("/medical_reports");
    }
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  const sendDataToParent = (files: any) => {
    setFiles(files);

    var merger = new PDFMerger();

    (async () => {
      for (const file of files) {
        await merger.add(file);
      }
      setPdfReport(await merger.saveAsBlob());
    })();
  };

  const callScanReportApi = async () => {
    const health_check_report_props: IScanHealthReportProps = {
      mobile: mobile,
      report_date: reportDate,
      pdf_file: pdfReport,
    };

    try {
      setIsLoading(true);
      const healthCheckReportScan: HealthCheckReportScanResponse =
        await healthCheckReportScanApi(health_check_report_props);
      if (healthCheckReportScan.status === 400) {
        setValidationResponse(healthCheckReportScan.data.result);
        setInputInvalidWindow(true);
        setIsLoading(false);
      } else {
        setReportResults(
          JSON.parse(JSON.stringify(healthCheckReportScan.messages)),
        );
        setIsLoading(false);
        setSubmittedWindow(true);
        setIsScanned(true);
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }

    // reportData = new Map<string, any>();
  };

  const callSubmitReportApi = async () => {
    const health_check_report_props: ISubmitHealthReportProps = {
      mobile: mobile,
      report_date: reportDate,
      data: reportResults,
      pdf_file: pdfReport,
    };

    const invalidList = ValidateSubmitItem(reportResults);

    const emptyList = AvoidEmptySubmitCheck(reportResults);
    if (emptyList.length > 0) {
      setInputInvalidWindow(true);
      setValidationResponse(emptyList.join(", ") + " cannot be empty");
    }else if (invalidList.length > 0) {
      setInputInvalidWindow(true);
      setValidationResponse(invalidList.join(", ") + " are invalid");
    }
    
    else {
      try {
        setIsLoading(true);
        const healthCheckReportScan: HealthCheckReportScanResponse =
          await healthCheckReportSubmitApi(health_check_report_props);
        if (healthCheckReportScan.status === 400) {
          setValidationResponse(healthCheckReportScan.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSubmittedWindow(true);
          setIsUploaded(true);
        }
      } catch (err) {
        console.log(err);
        setApiErrorWindow(true);
        setIsLoading(false);
      }
    }
  };

  const manualInputPdfCheck = () => {
    if (pdfReport) {
      setIsScanned(true);
    } else {
      setInputInvalidWindow(true);
      setValidationResponse("No PDF Selected");
    }
  };

  useEffect(() => {
    //Check cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, []);

  itemMapJson = JSON.parse(JSON.stringify(itemCodeMapping));

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <RouterLink to={"/medical_reports"}>
            <BreadcrumbLink>Medical Reports</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/health_check_report">
            Health Check Report
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Center>
          <Stack spacing={4}>
            {/* <Stack pl="20%"> */}
            <FormControl isInvalid={errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+852" />
                <Input
                  name="phone"
                  placeholder="Phone number [0-9]"
                  onInput={(event: any) =>
                    setMobile("+852" + event.target.value)
                  }
                  ref={register({
                    pattern: /^[0-9]{8}$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.phone ? "block" : "none"}>
                <i>This input requires 8 numbers only.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.reportDate}>
              <FormLabel>Report Date</FormLabel>
              <InputGroup>
                <Input
                  name="reportDate"
                  placeholder="YYYY-MM-DD"
                  value={reportDate}
                  onInput={(event: any) => setReportDate(event.target.value)}
                  ref={register({
                    pattern:
                      /[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.reportDate ? "block" : "none"}>
                <i>
                  This input requires the format of <b>YYYY-MM-DD</b>
                </i>
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </Center>
        {/* </Stack> */}
        {/* -------------- Health Report Main Component -------------- */}
        <Center>
          <Stack spacing={4}>
            <HealthReportMainCompoent
              sendDataToParent={sendDataToParent}
              isScanned={isScanned}
              reportResults={reportResults}
              setReportResults={setReportResults}
            />
            {/* -------------- Submit Button -------------- */}
            <Box textAlign="center">
              <Button
                type="button"
                loadingText="Submitting"
                colorScheme="teal"
                isLoading={isLoading}
                onClick={isScanned ? callSubmitReportApi : callScanReportApi}
              >
                {buttonText}
              </Button>
            </Box>

            {isScanned || isLoading ? (
              <></>
            ) : (
              <Box textAlign="center">
                <Button
                  type="button"
                  colorScheme="teal"
                  onClick={manualInputPdfCheck}
                >
                  Manual Input
                </Button>
              </Box>
            )}

            <br />
            <br />
            <br />
            {/* -------------- Submission completed window -------------- */}
            <AlertDialog
              isOpen={submittedWindow || apiErrorWindow}
              motionPreset="slideInBottom"
              onClose={
                submittedWindow ? closeSubmissionWindow : closeApiErrorWindow
              }
              leastDestructiveRef={initialRef}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    {submittedWindow ? "SUCCESS" : "SYSTEM ERROR"}
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <Button
                      ref={initialRef}
                      colorScheme="teal"
                      onClick={() =>
                        submittedWindow
                          ? closeSubmissionWindow()
                          : closeApiErrorWindow()
                      }
                    >
                      Close
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            {/* -------------- Input Invalid window -------------- */}
            <AlertDialog
              isOpen={inputInvalidWindow}
              motionPreset="slideInBottom"
              onClose={closeInputInvalidWindow}
              leastDestructiveRef={initialRef}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                  <AlertDialogBody>
                    <Text>
                      <b>{validationResponse}</b>.
                    </Text>
                    <Text>Please check your input and try again.</Text>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={initialRef}
                      colorScheme="teal"
                      onClick={() => closeInputInvalidWindow()}
                    >
                      Close
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
            {/* -------------- Cookies Timeout window -------------- */}
            <AlertDialog
              isOpen={cookieTimeoutWindow}
              motionPreset="slideInBottom"
              onClose={closeCookieTimeout}
              leastDestructiveRef={initialRef}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                  <AlertDialogBody>
                    <Text>Cookies timeout. Please login again.</Text>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button
                      ref={initialRef}
                      colorScheme="teal"
                      onClick={() => closeCookieTimeout()}
                    >
                      Okay
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Stack>
        </Center>
      </form>
    </Layout>
  );
};

const HealthReportMainCompoent = ({
  sendDataToParent,
  isScanned,
  reportResults,
  setReportResults,
}: any) => {
  return isScanned ? (
    <>
      <br />
      <Center>
        <Heading size="lg">Hematology</Heading>
      </Center>
      <Center>
        <HStack direction={"row"} spacing="20px">
          <Heading w="220px" size="md">
            Test Item
          </Heading>
          <Heading w="160px" size="md">
            Result
          </Heading>
          <Heading w="120px" size="md">
            Unit
          </Heading>
          <Heading w="220px" size="md">
            Reference Range
          </Heading>
        </HStack>
      </Center>
      <Center>
        <Heading size="md">Complete Blood Count (5 part DC)</Heading>
      </Center>
      {hematologyItems1.map((itemCode: string) =>
        brFormItemContainer(
          itemCode,
          itemMapJson,
          reportResults,
          setReportResults,
        ),
      )}

      <Center>
        <HStack direction={"row"} spacing="20px">
          <Spacer w="10px" />
          <Heading w="180px" size="md">
            Test Item
          </Heading>
          <Heading w="150px" size="md">
            Result
          </Heading>
          <Heading w="90px" size="md">
            Unit
          </Heading>
          <Heading w="150px" size="md">
            Reference Range
          </Heading>
          <Heading w="150px" size="md">
            Result
          </Heading>
          <Heading w="90px" size="md">
            Unit
          </Heading>
          <Heading w="100px" size="md">
            Reference Range
          </Heading>
        </HStack>
      </Center>
      <Center>
        <Heading size="md">Differential Count</Heading>
      </Center>

      {hematologyItems2.map((itemCode: string) =>
        brForm2ItemsContainer(
          itemCode,
          itemMapJson,
          reportResults,
          setReportResults,
        ),
      )}

      <Center>
        <Heading size="lg">Biochemistry</Heading>
      </Center>

      <Center>
        <HStack direction={"row"} spacing="20px">
          <Heading w="300px" size="md">
            Test Item
          </Heading>
          <Heading w="160px" size="md">
            Result
          </Heading>
          <Heading w="160px" size="md">
            Unit
          </Heading>
          <Heading w="300px" size="md">
            Reference Range
          </Heading>
        </HStack>
      </Center>

      {biochemicalItems.map((itemCode: string) =>
        biochemFormItemContainer(
          itemCode,
          itemMapJson,
          reportResults,
          setReportResults,
        ),
      )}

      <br />
    </>
  ) : (
    <>
      <br />
      <label>Please Upload the Health Check Report PDF File Here </label>
      <FileUploader sendDataToParent={sendDataToParent} />
      <br />
    </>
  );
};

const AvoidEmptySubmitCheck = (reportResults: any) => {
  const checkItem = [
    "glu_fast",
    "chol_good",
    "chol_bad",
    "chol_total",
    "triglycerides",
  ];
  const emptyList = [];

  for (let i = 0; i < checkItem.length; i++) {
    if (reportResults[checkItem[i]] == null) {
      emptyList.push(checkItem[i]);
    } else if (reportResults[checkItem[i]][0] == "") {
      emptyList.push(checkItem[i]);
    }
  }
  return emptyList;
};

const ValidateSubmitItem = (reportResults: any) => {
  const checkValidResult = (result: string) => {
    const doubleCheck = new RegExp("^$|^[+-]?([0-9]*[.])?[0-9]+$");
    if (result == null || !doubleCheck.test(result)) return false;
    else return true;
  };
  const invalidList: string[] = [];

  Object.keys(reportResults).map((key: any) => {
    var isValid: boolean;
    if (!checkValidResult(reportResults[key][0])) {
      invalidList.push("Result 1 of " + key);
    }
    if (!checkValidResult(reportResults[key][1])) {
      invalidList.push("Result 2 of " + key);
    }
  });
  return invalidList;
};

export default HealthCheckReport;
