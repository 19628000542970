import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cookie from "universal-cookie";
import {
  Stack,
  Box,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  FormErrorMessage,
  AlertDialogBody,
  AlertDialogFooter,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";

import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import { adminMobileUpgrade } from "../components/api";

interface MobileCheckProps {
  phone: string;
  user: string;
}

interface AdminMobileUpgradeResponse {
  ayncs(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

const Upgrade = () => {
  // ---------------------------- Hooks start ----------------------------
  const [phone, setPhone] = useState("");

  const [validationResponse, setValidationResponse] = useState(Object);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);
  const [confirmationWindow, setConfirmationWindow] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);

  // ---------------------------- Hooks end ----------------------------

  const closeConfirmationWindow = () => setConfirmationWindow(false);
  const closeInputInvalidWindow = () => setInputInvalidWindow(false);

  const { register, errors, handleSubmit } = useForm();
  const onSubmit = () => callApi();

  function closeSubmissionWindow() {
    setSubmittedWindow(false);
    history.push("/home");
  }

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/home");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  const callApi = async () => {
    const mobile_check_props: MobileCheckProps = {
      phone: phone,
      user: context.user,
    };
    try {
      setIsLoading(true);
      const apiResponse: AdminMobileUpgradeResponse = await adminMobileUpgrade(
        mobile_check_props,
      );
      if (apiResponse.status === 400) {
        setValidationResponse(apiResponse.data.result);
        setConfirmationWindow(false);
        setInputInvalidWindow(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSubmittedWindow(true);
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //Check cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, []);

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/upgrade">Upgrade</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Box pl="25%">
            <FormControl width="70%" isRequired isInvalid={errors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children="+852" />
                <Input
                  name="phone"
                  placeholder="Phone number [0-9]"
                  onInput={(event: any) =>
                    setPhone("+852" + event.target.value)
                  }
                  ref={register({
                    pattern: /^[0-9]{8}$/,
                  })}
                />
              </InputGroup>
              <FormErrorMessage display={errors.phone ? "block" : "none"}>
                <i>This input requires 8 numbers only.</i>
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box textAlign="center">
            <Button
              colorScheme="teal"
              isLoading={isLoading}
              loadingText="Submitting"
              type="submit"
            >
              Submit
            </Button>
          </Box>

          <AlertDialog
            isCentered
            isOpen={confirmationWindow}
            motionPreset="slideInBottom"
            onClose={closeConfirmationWindow}
            leastDestructiveRef={initialRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Upgrade account</AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to upgrade this account to{" "}
                  <b>premium</b>?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Stack direction="row" spacing={3}>
                    <Button onClick={closeConfirmationWindow}>Cancel</Button>
                    <Button
                      ref={initialRef}
                      isLoading={isLoading}
                      loadingText="Upgrading"
                      colorScheme="teal"
                      onClick={() => callApi()}
                    >
                      Upgrade
                    </Button>
                  </Stack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Submission completed window -------------- */}
          <AlertDialog
            isOpen={submittedWindow || apiErrorWindow}
            motionPreset="slideInBottom"
            onClose={
              submittedWindow ? closeSubmissionWindow : closeApiErrorWindow
            }
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  {submittedWindow ? "SUCCESS" : "ERROR"}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() =>
                      submittedWindow
                        ? closeSubmissionWindow()
                        : closeApiErrorWindow()
                    }
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Input Invalid window -------------- */}
          <AlertDialog
            isOpen={inputInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeInputInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>
                    <b>{validationResponse}</b>.
                  </Text>
                  <Text>Please check your input and try again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeInputInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Cookies Timeout window -------------- */}
          <AlertDialog
            isOpen={cookieTimeoutWindow}
            motionPreset="slideInBottom"
            onClose={closeCookieTimeout}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Cookies timeout. Please login again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeCookieTimeout()}
                  >
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
      </form>
    </Layout>
  );
};

export default Upgrade;
