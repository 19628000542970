import { baseRequest } from "./baseRequest";

const url = "/admin/auth/reset-password";

interface IProps {
    userName: string,
    password: string,
    user: string
}

export const adminPortalResetPassword = (props:IProps) => {
    return (
        baseRequest.put(url, {
            user_name: props.userName,
            password: props.password
        },
        {
            params: {
                role: props.user
            },
            
            headers: {
                'Content-Type': 'application/json',
                'Token': process.env.REACT_APP_API_KEY
            }
        })

        .then ((response) => {
            return (response.data);
        })

        .catch((err) => {
            if (err.response.status === 400)
                return err.response;
            else
                throw (err);
        })
    );
}