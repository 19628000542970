import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Cookie from "universal-cookie";
import { Link as RouterLink } from "react-router-dom";
import {
  createOrganizationRequest,
  createPortalValidationPromotion,
  createPromotionCodePrefixRequest,
  portalRegister,
  adminPortalResetPassword,
  adminPatchOrganization,
} from "../components/api";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  Button,
  Box,
  Heading,
  Text,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormErrorMessage,
} from "@chakra-ui/react";

import Layout from "../components/shared/Layout";
import AppContext from "../components/shared/AppContext";
import { useForm } from "react-hook-form";

type syncDataBy = "p" | "c";

// -------------- interface for input fields --------------
interface OrgProps {
  orgInput: string;
  chineseName: string;
  englishName: string;
  type: string;
  user: string;
}

interface PromoProps {
  orgInput: string;
  promoPrefix: string;
  startDate: string;
  endDate: string;
  quota: number;
  dataConsent: number;
  premiumUpgrade: number;
  discount: number;
  upgradePeriod: number;
  user: string;
}

interface PortalProps {
  orgInput: string;
  id: number;
  userName: string;
  password: string;
  syncDataBy: syncDataBy;
  user: string;
}

interface ResetPasswordProps {
  userName: string;
  password: string;
  user: string;
}

interface PatchOrganizationProps {
  chineseName: string;
  englishName: string;
  orgInput: string;
  type: string;
  user: string;
}

interface ValidationProps {
  orgInput: string;
  password: string;
  promoPrefix: string;
  userName: string;
  syncDataBy: syncDataBy;
  user: string;
}

// -------------- interface for api response --------------
interface CreatePortalValidationPromotionResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: {
      [key: string]: string;
    };
  };
}

interface AdminPortalResetPasswordResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

interface AdminPatchOrganizationResponse {
  async(): Promise<string>;
  status: number;
  data: {
    result: string;
  };
}

interface CreateOrganizationRequestResponse {
  async(): Promise<string>;
  data: {
    organization_id: number;
  };
}

interface CreatePromotionCodePrefixRequestResponse {
  async(): Promise<string>;
}

interface PortalRegisterResponse {
  async(): Promise<string>;
}

const Promotion = () => {
  // ---------------------------- Hooks start ----------------------------

  // -------------- Hooks to hold input values --------------
  const [orgInput, setOrgInput] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [quota, setQuota] = useState(-1);
  const [premiumUpgrade, setPremiumUpgrade] = useState(0);
  const [promoPrefix, setPromoPrefix] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");

  // -------------- Hook to validate popup window input --------------
  const [inputInvalid, setInputInvalid] = useState(true);

  // -------------- Others --------------
  const [validationResponse, setValidationResponse] = useState(Object);
  const [patchResponse, setPatchResponse] = useState(Object);
  const [resetResponse, setResetResponse] = useState(Object);
  const [submittedWindow, setSubmittedWindow] = useState(false);
  const [inputInvalidWindow, setInputInvalidWindow] = useState(false);
  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [isUpdateDetails, setIsUpdateDetails] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [updateDetailsData, setUpdateDetailsData] = useState(Object);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [temp, setTemp] = useState(0);
  const [field, setField] = useState("");

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);
  const { errors, register, handleSubmit } = useForm();

  // ---------------------------- Hooks end ----------------------------

  const closeInputInvalidWindow = () => setInputInvalidWindow(false);
  const onClose = () => setIsOpen(false);
  const onSubmit = () => callApi();

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/home");
  }

  function closeSubmissionWindow() {
    setSubmittedWindow(false);
    history.push("/home");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  const date = (input: number) => {
    let today = new Date();
    let day = String(today.getDate()).padStart(2, "0");
    let month = String(today.getMonth() + 1).padStart(2, "0");
    let year = today.getFullYear() + input;
    return String(year) + "-" + month + "-" + day;
  };

  function openPopUp(field: string) {
    setIsOpen(true);
    setField(field);
    setInputInvalid(true);
  }

  function handlePopWindow(event: any) {
    if (
      field === "Premium Upgrade" &&
      !RegExp("^[0-1]$").test(event.target.value)
    ) {
      event.target.style.color = "red";
      setInputInvalid(true);
    } else if (
      field === "Quota" &&
      !RegExp("^-?[0-9]+$").test(event.target.value)
    ) {
      event.target.style.color = "red";
      setInputInvalid(true);
    } else {
      event.target.style.color = "black";
      setInputInvalid(false);
      setTemp(parseInt(event.target.value, 10));
    }
  }

  function updateValue(temp: number) {
    onClose();
    field === "Quota" ? setQuota(temp) : setPremiumUpgrade(temp);
  }

  useEffect(() => {
    setStartDate(date(0));
    setEndDate(date(3));

    // check if history has state data
    const fromDetails = history.location.state;
    if (fromDetails) {
      console.log(fromDetails[0]);
      if (fromDetails[1] === "Update Details") {
        setIsUpdateDetails(true);
        setChineseName(fromDetails[0]["name_l1"]);
        setEnglishName(fromDetails[0]["name_l2"]);
        setType(fromDetails[0]["type"]);
      } else if (fromDetails[1] === "Reset Password") {
        setIsResetPassword(true);
        setUserName(fromDetails[0]["user_name"]);
      }
      setOrgInput(fromDetails[0]["organizer"]);
      setUpdateDetailsData(fromDetails[0]);
    }

    // Check for cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, [history.location.state]);

  const callApi = async () => {
    const org_props: OrgProps = {
      orgInput: orgInput,
      chineseName: chineseName,
      englishName: englishName,
      type: type,
      user: context.user,
    };

    const promo_props: PromoProps = {
      orgInput: orgInput,
      promoPrefix: promoPrefix,
      startDate: startDate,
      endDate: endDate,
      quota: quota,
      dataConsent: 1,
      premiumUpgrade: premiumUpgrade,
      discount: 0,
      upgradePeriod: 0,
      user: context.user,
    };

    const portal_props: PortalProps = {
      orgInput: orgInput,
      id: 0,
      userName: userName,
      password: password,
      syncDataBy: "p",
      user: context.user,
    };

    const validation_props: ValidationProps = {
      orgInput: orgInput,
      password: password,
      promoPrefix: promoPrefix,
      userName: userName,
      syncDataBy: "p",
      user: context.user,
    };

    const reset_password_props: ResetPasswordProps = {
      userName: userName,
      password: password,
      user: context.user,
    };

    const patch_organization_props: PatchOrganizationProps = {
      chineseName: chineseName,
      englishName: englishName,
      orgInput: orgInput,
      type: type,
      user: context.user,
    };

    try {
      setIsLoading(true);
      if (isUpdateDetails) {
        const patchOrganizationResponse: AdminPatchOrganizationResponse =
          await adminPatchOrganization(patch_organization_props);
        if (patchOrganizationResponse.status === 400) {
          setPatchResponse(patchOrganizationResponse.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      } else if (isResetPassword) {
        const resetPasswordResponse: AdminPortalResetPasswordResponse =
          await adminPortalResetPassword(reset_password_props);
        if (resetPasswordResponse.status === 400) {
          setResetResponse(resetPasswordResponse.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      } else {
        const portalValidation: CreatePortalValidationPromotionResponse =
          await createPortalValidationPromotion(validation_props);
        if (portalValidation.status === 400) {
          setValidationResponse(portalValidation.data.result);
          setInputInvalidWindow(true);
          setIsLoading(false);
        } else {
          const orgReq: CreateOrganizationRequestResponse =
            await createOrganizationRequest(org_props);
          portal_props.id = orgReq.data.organization_id;
          const promoReq: CreatePromotionCodePrefixRequestResponse =
            await createPromotionCodePrefixRequest(promo_props);
          const portalReg: PortalRegisterResponse = await portalRegister(
            portal_props,
          );
          setIsLoading(false);
          setSubmittedWindow(true);
        }
      }
    } catch (err) {
      console.log(err);
      setApiErrorWindow(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem
          display={isUpdateDetails || isResetPassword ? "inline" : "none"}
        >
          <RouterLink to={"/details"}>
            <BreadcrumbLink>Details</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/promotion">Promotion</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* -------------- Organizer starts -------------- */}
          <Heading size="lg">Organizer</Heading>
          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.organizer}
              isDisabled={isUpdateDetails || isResetPassword ? true : false}
              isRequired
            >
              <FormLabel>Organizer ID (For searching in portal)</FormLabel>
              <Input
                name="organizer"
                placeholder="Organizer's ID (eg. abc01)"
                onInput={(event: any) => setOrgInput(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["organizer"]
                    : ""
                }
                ref={register({
                  minLength: 5,
                })}
              />
              <FormErrorMessage display={errors.organizer ? "block" : "none"}>
                <i>This input requires a minimum length of 5.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl isDisabled>
              <FormLabel>UID (No need to fill in)</FormLabel>
              <Input
                value={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["organizer"]
                    : orgInput
                }
              />
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.chinesename}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Name (Chinese)</FormLabel>
              <Input
                name="chinesename"
                placeholder="Name in Chinese (e.g.聖公會xxx中心)"
                onInput={(event: any) => setChineseName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["name_l1"]
                    : ""
                }
                ref={register({
                  required: true,
                })}
              />
            </FormControl>
            <FormControl
              isInvalid={errors.englishname}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Name (English)</FormLabel>
              <Input
                name="englishname"
                placeholder="Name in English"
                onInput={(event: any) => setEnglishName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["name_l2"]
                    : ""
                }
              />
            </FormControl>
          </Stack>

          {/* -------------- Promotion starts -------------- */}
          <Heading size="lg">Promotion Code</Heading>
          <Stack direction="row" spacing={3} align="center">
            <FormControl isDisabled>
              <FormLabel>
                Promotion Code Quota (no need to change, default setting =
                unlimited)
              </FormLabel>
              <Input value={quota} />
              <FormHelperText fontSize="xs">
                <i>Click on the edit button to update.</i>
              </FormHelperText>
            </FormControl>
            <Button
              isDisabled={isResetPassword || isUpdateDetails ? true : false}
              width="100px"
              onClick={() => openPopUp("Quota")}
            >
              Edit
            </Button>
            <FormControl isDisabled>
              <FormLabel>Premium Upgrade (0 = No, 1 = Yes)</FormLabel>
              <Input value={premiumUpgrade} />
              <FormHelperText fontSize="xs">
                <i>Click on the edit button to update.</i>
              </FormHelperText>
            </FormControl>
            <Button
              isDisabled={isResetPassword || isUpdateDetails ? true : false}
              width="100px"
              onClick={() => openPopUp("Premium Upgrade")}
            >
              Edit
            </Button>

            {/* -------------- Window start -------------- */}
            <AlertDialog
              isCentered
              motionPreset="slideInBottom"
              isOpen={isOpen}
              leastDestructiveRef={initialRef}
              onClose={onClose}
              colorScheme="teal"
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>{field}</AlertDialogHeader>
                  <AlertDialogBody>
                    <FormControl isRequired>
                      <FormLabel>Enter the new value below</FormLabel>
                      <Input
                        id="popwindow"
                        onInput={(event: any) => handlePopWindow(event)}
                      />
                      <FormHelperText
                        display={field === "Premium Upgrade" ? "block" : "none"}
                      >
                        0 --&gt; Yes | 1 --&gt; No
                      </FormHelperText>
                      <Text
                        display={inputInvalid ? "block" : "none"}
                        color="#718096"
                        fontSize="sm"
                      >
                        <i>
                          {field === "Premium Upgrade"
                            ? "This field only accepts 0 and 1 as input"
                            : "This field only accepts integers as input"}
                        </i>
                      </Text>
                    </FormControl>
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Stack direction="row" spacing={3}>
                      <Button onClick={onClose}>Close</Button>
                      <Button
                        isDisabled={inputInvalid}
                        onClick={() => updateValue(temp)}
                        colorScheme="teal"
                      >
                        Update
                      </Button>
                    </Stack>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
            {/* -------------- Window end -------------- */}
          </Stack>

          <FormLabel>Validity</FormLabel>
          <Stack direction="row" spacing={3}>
            <FormControl
              isInvalid={errors.startdate}
              isDisabled={isUpdateDetails || isResetPassword ? true : false}
            >
              <Input
                name="startdate"
                placeholder="YYYY-MM-DD"
                defaultValue={startDate}
                onInput={(event: any) => setStartDate(event.target.value)}
                ref={register({
                  pattern:
                    /[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                })}
              />
              <FormErrorMessage display={errors.startdate ? "block" : "none"}>
                <i>
                  This input requires the format of <b>YYYY-MM-DD</b>
                </i>
              </FormErrorMessage>
            </FormControl>
            <Text fontSize="2xl">-</Text>
            <FormControl
              isInvalid={errors.enddate}
              isDisabled={isUpdateDetails || isResetPassword ? true : false}
            >
              <Input
                name="enddate"
                placeholder="YYYY-MM-DD"
                defaultValue={endDate}
                onInput={(event: any) => setEndDate(event.target.value)}
                ref={register({
                  pattern:
                    /[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                })}
              />
              <FormErrorMessage display={errors.enddate ? "block" : "none"}>
                <i>
                  This input requires the format of <b>YYYY-MM-DD</b>
                </i>
              </FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack direction="row" spacing={4}>
            <FormControl
              isInvalid={errors.promoprefix}
              isRequired
              isDisabled={isUpdateDetails || isResetPassword ? true : false}
            >
              <FormLabel>Promotion Code</FormLabel>
              <Input
                name="promoprefix"
                placeholder="Prefix"
                onInput={(event: any) => setPromoPrefix(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["promotion_code_prefix"]
                    : ""
                }
                ref={register({
                  minLength: 5,
                })}
              />
              <FormErrorMessage display={errors.promoprefix ? "block" : "none"}>
                <i>This input requires a minimum length of 5.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.type}
              isRequired
              isDisabled={isResetPassword ? true : false}
            >
              <FormLabel>Type</FormLabel>
              <Select
                name="type"
                placeholder="Select type"
                onInput={(event: any) => setType(event.target.value)}
                value={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["type"]
                    : type
                }
                ref={register({
                  required: true,
                })}
              >
                <option>Clinic</option>
                <option>Centre</option>
                <option>Internal</option>
                <option>Commercial</option>
              </Select>
            </FormControl>
          </Stack>

          {/* -------------- Create Account starts -------------- */}
          <Heading size="lg">Create Account</Heading>
          <Stack pl="25%">
            <FormControl
              isInvalid={errors.username}
              isDisabled={isUpdateDetails ? true : false}
              isRequired
              width="70%"
            >
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                placeholder="Username (min. 6 characters)"
                onInput={(event: any) => setUserName(event.target.value)}
                defaultValue={
                  isUpdateDetails || isResetPassword
                    ? updateDetailsData["user_name"]
                    : ""
                }
                ref={register({
                  minLength: 6,
                })}
              />
              <FormErrorMessage display={errors.username ? "block" : "none"}>
                <i>This input requires a minimum length of 6.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.password}
              isDisabled={isUpdateDetails ? true : false}
              isRequired
              width="70%"
            >
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password (min. 6 characters)"
                onInput={(event: any) => setPassword(event.target.value)}
                ref={register({
                  minLength: 6,
                })}
              />
              <FormErrorMessage display={errors.password ? "block" : "none"}>
                <i>This input requires a minimum length of 6.</i>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.confirm}
              isDisabled={isUpdateDetails ? true : false}
              isRequired
              width="70%"
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                name="confirm"
                type="password"
                placeholder="Confirm password (min. 6 characters)"
                ref={register({
                  validate: (value: any) =>
                    value ===
                    (document.getElementById("password") as HTMLInputElement)
                      ?.value,
                })}
              />
              <FormErrorMessage display={errors.confirm ? "block" : "none"}>
                <i>This input doesn't match Password.</i>
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Box textAlign="center">
            <Button
              isLoading={isLoading}
              loadingText="Submitting"
              colorScheme="teal"
              type="submit"
            >
              Submit
            </Button>
          </Box>

          {/* -------------- Submission completed window -------------- */}
          <AlertDialog
            isOpen={submittedWindow || apiErrorWindow}
            motionPreset="slideInBottom"
            onClose={
              submittedWindow ? closeSubmissionWindow : closeApiErrorWindow
            }
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>
                  {submittedWindow ? "SUCCESS" : "ERROR"}
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() =>
                      submittedWindow
                        ? closeSubmissionWindow()
                        : closeApiErrorWindow()
                    }
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Input Invalid window -------------- */}
          <AlertDialog
            isOpen={inputInvalidWindow}
            motionPreset="slideInBottom"
            onClose={closeInputInvalidWindow}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Invalid input</AlertDialogHeader>
                <AlertDialogBody>
                  {isResetPassword && !isUpdateDetails ? (
                    <Text>
                      <b>{resetResponse}</b>
                    </Text>
                  ) : (
                    <div></div>
                  )}
                  {!isResetPassword && isUpdateDetails ? (
                    <Text>{patchResponse}</Text>
                  ) : (
                    <div></div>
                  )}
                  {isResetPassword || isUpdateDetails ? (
                    <div></div>
                  ) : (
                    Object.keys(validationResponse).map(function (key, index) {
                      return (
                        <Text key={index}>
                          <b>{validationResponse[key]}</b>.
                        </Text>
                      );
                    })
                  )}
                  <Text>Please check your input and try again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeInputInvalidWindow()}
                  >
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* -------------- Cookies Timeout window -------------- */}
          <AlertDialog
            isOpen={cookieTimeoutWindow}
            motionPreset="slideInBottom"
            onClose={closeCookieTimeout}
            leastDestructiveRef={initialRef}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Cookies timeout. Please login again.</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={initialRef}
                    colorScheme="teal"
                    onClick={() => closeCookieTimeout()}
                  >
                    Okay
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
      </form>
    </Layout>
  );
};

export default Promotion;
