import { useEffect, useState } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Cookie from "universal-cookie";

import Centre from "./pages/Centre";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Promotion from "./pages/Promotion";
import Upgrade from "./pages/Upgrade";
import AbpmReport from "./pages/AbpmReport"
// import Usage from "./pages/Usage";
import Staff from "./pages/Staff";
import MobileRegister from "./pages/MobileRegister";
import Details from "./pages/Details";
import AppContext from "./components/shared/AppContext";
import MedicalReports from "./pages/MedicalReports";
import HealthCheckReport from "./pages/HealthCheckReport"

interface ContextProps {
  isAuthenticate: boolean,
  user: string,
  setIsAuthenticate: React.Dispatch<React.SetStateAction<boolean>>,
  setUser: React.Dispatch<React.SetStateAction<string>>
}

function App() {
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [user, setUser] = useState("");

  // Global hook to check cookie timeout
  const context_props: ContextProps = {
    isAuthenticate,
    user,
    setIsAuthenticate,
    setUser
  }

  
  const PrivateRoute = ({component: Component}: any) => {
    return (
      <Route render = {() => isAuthenticate ? <Component /> : <Redirect to = {"/"} />} />
    )
  }

  useEffect(() => {
    // Check cookie
    const cookie = new Cookie();
    cookie.get("authenticate") ? setIsAuthenticate(true) : setIsAuthenticate(false);
    return () => {
      cookie.remove("authenticate");
    }
  }, [])

  return (
    <AppContext.Provider value = {context_props}>
      <Router>
        <Switch>
          <PrivateRoute exact path = {'/home'} component = {Home} />

          <PrivateRoute exact path = {'/centre'} component = {Centre} />

          <PrivateRoute exact path = {'/promotion'} component = {Promotion} />

          {/* <PrivateRoute exact path = {'/usage'} component = {Usage} /> */}

          <PrivateRoute exact path = {'/staff'} component = {Staff} />

          <PrivateRoute exact path = {'/upgrade'} component = {Upgrade} />

          <PrivateRoute exact path = {'/medical_reports'} component = {MedicalReports} />

          <PrivateRoute exact path = {'/health_check_reports'} component = {HealthCheckReport} />

          <PrivateRoute exact path = {'/mobileregister'} component = {MobileRegister} />

          <PrivateRoute exact path = {'/abpm_reports'} component = {AbpmReport} />

          <PrivateRoute exact path = {'/details'} component = {Details} />

          <Route path = {'/'} component = {Login} />
        </Switch>
      </Router>
    </AppContext.Provider>
    
  );
}

export default App;
