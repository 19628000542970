import { useState, useRef, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import Cookie from "universal-cookie";
import DataTable from "react-data-table-component";
import AppContext from "../components/shared/AppContext";

import Layout from "../components/shared/Layout";
import { adminPortalUsersResponse } from "../components/api";
import {
  Box,
  FormControl,
  Input,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";

type portalType = "Promotion Code Portal" | "Centre Portal";
type portalAction = "Update" | "Reset Password";

interface ApiProps {
  user: string;
}

const Details = () => {
  // ---------------------------- Hooks start ----------------------------

  // -------------- Input values --------------
  const [searchText, setSearchText] = useState("");

  // -------------- Others --------------

  const [tableLoading, setTableLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [cookieTimeoutWindow, setCookieTimeoutWindow] = useState(false);
  const [apiErrorWindow, setApiErrorWindow] = useState(false);
  const [rowData, setRowData] = useState(Object);
  const [openPromotionPage, setOpenPromotionPage] = useState(false);
  const [openCentrePage, setOpenCentrePage] = useState(false);
  const [isUpdateDetails, setIsUpdateDetails] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const initialRef = useRef<any>();
  const history = useHistory();
  const context = useContext(AppContext);

  // ---------------------------- Hooks end ----------------------------

  function closeApiErrorWindow() {
    setApiErrorWindow(false);
    history.push("/home");
  }

  function closeCookieTimeout() {
    setCookieTimeoutWindow(false);
    history.push("/");
    context.setIsAuthenticate(false);
  }

  function handleEdit(event: any, name: portalAction) {
    setRowData(event);
    const syncDataBy: portalType = event.sync_data_by;
    switch (syncDataBy) {
      case "Promotion Code Portal":
        setOpenPromotionPage(true);
        break;
      case "Centre Portal":
        setOpenCentrePage(true);
        break;
    }
    switch (name) {
      case "Update":
        setIsUpdateDetails(true);
        break;
      case "Reset Password":
        setIsResetPassword(true);
        break;
    }
  }

  const filteredData = allData?.filter(
    (item: any) =>
      (item.name_l1 &&
        item.name_l1.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.name_l2 &&
        item.name_l2.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.mobile &&
        item.mobile.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.organizer &&
        item.organizer.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.promotion_code_prefix &&
        item.promotion_code_prefix
          .toLowerCase()
          .includes(searchText.toLowerCase())) ||
      (item.sync_data_by &&
        item.sync_data_by.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.type &&
        item.type.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.cloud_type &&
        item.cloud_type.toLowerCase().includes(searchText.toLowerCase())) ||
      (item.user_name &&
        item.user_name.toLowerCase().includes(searchText.toLowerCase())),
  );

  const ReactLibTable = () => {
    return (
      <DataTable
        title="User Data"
        columns={columns}
        data={filteredData}
        progressPending={tableLoading}
        striped
        pagination
      />
    );
  };

  const loadTable = async () => {
    const api_props: ApiProps = {
      user: context.user,
    };

    try {
      setTableLoading(true);
      const userDetails = await adminPortalUsersResponse(api_props);
      setAllData(userDetails);
      setTableLoading(false);
    } catch (err) {
      setApiErrorWindow(true);
    }
  };

  const columns: any = [
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
      wrap: true,
    },
    {
      name: "Chinese Name",
      selector: "name_l1",
      sortable: true,
      wrap: true,
    },
    {
      name: "English Name",
      selector: "name_l2",
      sortable: true,
      wrap: true,
    },
    {
      name: "Organizer's UID",
      selector: "organizer",
      sortable: true,
      wrap: true,
    },
    {
      name: "Promotion Code Prefix",
      selector: "promotion_code_prefix",
      sortable: true,
      wrap: true,
    },
    {
      name: "Sync Data By",
      selector: "sync_data_by",
      sortable: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      wrap: true,
    },
    {
      name: "Username",
      selector: "user_name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Cloud Type",
      selector: "cloud_type",
      sortable: true,
      wrap: true,
    },
    {
      name: "Organization Details",
      cell: (event: any) => (
        <Button
          display={
            event["sync_data_by"].toLowerCase().includes("portal")
              ? "block"
              : "none"
          }
          onClick={() => handleEdit(event, "Update")}
          colorScheme="teal"
          size="sm"
        >
          Update
        </Button>
      ),
      button: true,
    },
    {
      name: "Reset Password",
      cell: (event: any) => (
        <Button
          display={
            event["sync_data_by"].toLowerCase().includes("portal")
              ? "block"
              : "none"
          }
          onClick={() => handleEdit(event, "Reset Password")}
          colorScheme="teal"
          size="sm"
        >
          Reset
        </Button>
      ),
      button: true,
    },
  ];

  useEffect(() => {
    loadTable();
    if (openPromotionPage) {
      if (isResetPassword) {
        history.push("/promotion", [rowData, "Reset Password"]);
        setIsResetPassword(false);
      } else if (isUpdateDetails) {
        history.push("/promotion", [rowData, "Update Details"]);
        setIsUpdateDetails(false);
      }
    } else if (openCentrePage) {
      if (isResetPassword) {
        history.push("/centre", [rowData, "Reset Password"]);
        setIsResetPassword(false);
      } else if (isUpdateDetails) {
        history.push("/centre", [rowData, "Update Details"]);
        setIsUpdateDetails(false);
      }
    }
    // Check for cookie
    const cookie = new Cookie();
    if (!cookie.get("authenticate")) setCookieTimeoutWindow(true);
  }, [openCentrePage, openPromotionPage]);

  return (
    <Layout>
      <Breadcrumb textAlign="center" fontSize="md" color="#718096" my={6}>
        <BreadcrumbItem>
          <RouterLink to={"/home"}>
            <BreadcrumbLink>Home</BreadcrumbLink>
          </RouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/details">Details</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mb={4}>
        <FormControl ml="auto" mr={0} width="30%">
          <Input
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
          />
        </FormControl>
      </Box>

      {/* -------------- Table start -------------- */}
      <ReactLibTable />

      {/* -------------- API Error window -------------- */}
      <AlertDialog
        isOpen={apiErrorWindow}
        motionPreset="slideInBottom"
        onClose={closeApiErrorWindow}
        leastDestructiveRef={initialRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Error in retrieving data</AlertDialogHeader>
            <AlertDialogFooter>
              <Button
                ref={initialRef}
                colorScheme="teal"
                onClick={() => closeApiErrorWindow()}
              >
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* -------------- Cookies Timeout window -------------- */}
      <AlertDialog
        isOpen={cookieTimeoutWindow}
        motionPreset="slideInBottom"
        onClose={closeCookieTimeout}
        leastDestructiveRef={initialRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Oops... Timeout</AlertDialogHeader>
            <AlertDialogBody>
              <Text>Cookies timeout. Please login again.</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={initialRef}
                colorScheme="teal"
                onClick={() => closeCookieTimeout()}
              >
                Okay
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  );
};

export default Details;
